<template>
    <MainAppContainer>
        <HowItWork />
        <DownloadApp />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import HowItWork from '@/components/pages/HowItWork.vue'
import DownloadApp from '@/components/common/DownloadApp'
export default {
    components: {
        MainAppContainer,
        HowItWork,
        DownloadApp
    }
}
</script>