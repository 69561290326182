<template>
    <div class="overflow-hidden">
        <!---------- Banner ---------->
        <section class="py-sm-0 py-5 d-none d-sm-block" style="background-color: #f5f5f5">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-sm-6 col-lg-6 order-sm-2 pe-0 text-center how_it_works d-none d-sm-block" style="margin-bottom: -6px">
                        <video src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/how.mp4" style="width: 100%" loop autoplay muted></video>
                        <!-- <img :src="howWorkData.banner_image" class="img-fluid" /> -->
                    </div>

                    <div class="col-sm-6 col-lg-5 order-sm-1 offset-lg-1 ps-lg-5 py-4 py-md-1 ps-4 ps-sm-3">
                        <h2>{{howWorkData.title}}</h2>
                        <!-- <h2>Discover the tools you need to grow your business</h2> -->
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span class="banner-text">A flexible plan that fits any size of business</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span class="banner-text">Use real-time virtual queue</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span class="banner-text">Marketing tools built for your business</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span class="banner-text">Send quotation easily</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span class="banner-text">Easy-to-use calendar</span>
                            </div>
                        </div>
                        <!-- <p>OnDaQ is easy to use, enjoyable and usable.</p> -->
                        <a :href="howWorkData.button_link" class="btn btn-ondaq-dark py-2 px-4">{{howWorkData.button_text}}</a>
                    </div>
                    <!-- <div class="col-sm-6 col-lg-5 order-sm-2 ps-lg-5 text-center how_it_works d-none d-sm-block">
                        <video src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/how.mp4" width="425" controls autoplay muted></video>
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/bannerImage.png" class="img-fluid" />
                    </div>

                    <div class="col-sm-6 col-lg-5 order-sm-1 offset-lg-1 ps-lg-5 pt-4 pt-md-1 ps-4 ps-sm-3">
                        <h2 class="mb-3">How it works</h2>
                        <ul class="ps-3" style="list-style-type: disc">
                            <li>Salons have started managing their clients virtually.</li>
                            <li>Get booked very fast</li>
                            <li>Send quotation to your clients</li>
                            <li>Give clients discounts</li>
                        </ul>
                        <p>You can Join a Queue from Anywhere Know the numbers of tickets on the waitlist Get Estimated Wait Time of a Queue Book an Appointment Fast Request a Quote See Package Deals Near You</p>
                        <a href="#" style="color: #6e317a" class="pe-2"> Learn more</a>
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" />
                        <button class="btn btn-ondaq-dark py-2 px-4">List My Business</button>
                    </div> -->
                </div>
            </div>
        </section>

        <section class="d-block d-sm-none pt-0 pb-3">
            <div class="container-fluid section_height">
                <div class="row align-items-center">
                    <video src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/how.mp4" loop autoplay muted id="myVideo"></video>
                    <div class="video-content">
                        <h2 class="text-white">Discover the tools you need to grow your business</h2>
                        <p class="text-white">OnDaQ is easy to use, enjoyable and usable.</p>
                        <button class="btn btn-ondaq-dark custom-bg-color py-2 px-4">List My Business</button>
                    </div>
                </div>
            </div>
        </section>

        <!---------- Get Started ---------->
        <section class="container">
            <div class="row text-center get_started section_height">
                <div class="col-12 pb-4">
                    <h3>Get Started</h3>
                </div>
                <div class="col-lg-3 col-md-6 py-2 py-md-0">
                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/gs_1.png" class="img-fluid" />
                    <h5 class="pt-3 pb-2 m-0">Start your free trial</h5>
                    <p>Create your business account, add your business schedules, team members, services and how you get paid. Enjoy the free trial!</p>
                    <!-- <p>Create an account and get familiar with our time management tools free of charge for 90 days! No credit or debit card needed to signup.</p> -->
                </div>

                <div class="col-lg-3 col-md-6 py-2 py-md-0">
                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/gs_2.png" class="img-fluid" />
                    <h5 class="pt-3 pb-2 m-0">Setup Your Business</h5>
                    <p>Build your business profile, list your services and manage your schedule as seamlessly as possible.</p>
                </div>

                <div class="col-lg-3 col-md-6 py-2 py-md-0">
                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/gs_3.png" class="img-fluid" />
                    <h5 class="pt-3 pb-2 m-0">Get Clients</h5>
                    <p>OnDaQ marketing campaign tool is built for you to grow your business and stay connected with your existing clients and potential clients</p>
                </div>

                <div class="col-lg-3 col-md-6 py-2 py-md-0">
                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/gs_4.png" class="img-fluid" />
                    <h5 class="pt-3 pb-2 m-0">Get Your Money</h5>
                    <p>We partnered with Stripe to ensure that all your payments are safe and secure.</p>
                </div>
            </div>
        </section>

        <!---------- Quick Book ---------->
        <section style="background-color: #ecebf5;">
            <div class="container section_height">
                <div class="row align-items-center">
                    <!-- <div class="col-12 pb-4 text-center">
                        <h3>Manage your business with schedule management tools</h3>
                    </div> -->
                    <div class="col-md-6 col-lg-5 order-md-2 ps-lg-5 text-center text-md-start">
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/quick_look.png" class="img-fluid" />
                    </div>

                    <div class="col-md-6 col-lg-5 order-md-1 offset-lg-1 ps-lg-5 pt-4 pt-md-1">
                        <h2 class="mb-4">Get booked while you focus on your clients</h2>
                        <p>Our marketplace gives you the visibility to get more clients and clients can book online or walk into your salon. OnDaQ calendar is easy to use across all platforms, laptops, and mobile phones.</p>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>Online Booking</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>Your professionals will have their separate schedules</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>The manager or owner can manage employees</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>Get booked quickly</span>
                            </div>
                        </div>
                        <!-- <a href="#" class="pe-2 learn_more"> Learn more</a> -->
                        <!-- <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" /> -->
                    </div>

                </div>
            </div>
        </section>

        <!---------- Daily Deals ---------->
        <section>
            <div class="container section_height">
                <div class="row align-items-center">
                    <div class="col-md-6 col-lg-5 offset-lg-1 ps-lg-5 text-center text-md-start">
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/daily_deals.png" class="img-fluid" />
                    </div>

                    <div class="col-md-6 col-lg-5 ps-lg-5 pt-4 pt-md-1">
                        <h2 class="mb-3">Daily Deals</h2>
                        <p>Use this powerful tool to increase your clientele, when you offer deals, most clients will continue to patronize your business.</p>
                        <!-- <a href="#" class="pe-2"> Learn more</a> -->
                        <!-- <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" /> -->
                    </div>

                </div>
            </div>
        </section>

        <!---------- Get Quote ---------->
        <section style="background-color: #f4f4f4">
            <div class="container section_height">
                <div class="row align-items-center">
                    <div class="col-md-6 col-lg-5 order-md-2 ps-lg-5 text-center text-md-start">
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/get_quote.png" class="img-fluid" />
                    </div>

                    <div class="col-md-6 col-lg-5 order-md-1 offset-lg-1 ps-lg-5 pt-4 pt-md-1">
                        <h2 class="mb-3">Send Quotation</h2>
                        <p>There are so many services requests that clients would be:</p>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>Use OnDaQ messaging to communicate and get client’s requirement</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>Send a custom price quotes to clients</span>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-1">
                                <fa class="checkcircle" :icon="['fas', 'check']" />
                            </div>
                            <div class="col-11 p-md-0">
                                <span>Add the service to your calendar</span>
                            </div>
                        </div>
                        <!-- <a href="#" style="color: #6e317a;" class="pe-2"> Learn more</a> -->
                        <!-- <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" /> -->
                    </div>

                </div>
            </div>
        </section>

        <!---------- Need Help ---------->
        <section class="container-fluid">
            <div class="row align-items-center section_height">
                <div class="col-md-2 col-lg-1 offset-lg-2 offset-md-1 d-none d-md-block">
                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/search_with_gear.png" class="img-fluid" />
                </div>
                <div class="col-md-5">
                    <h3>Need Help?</h3>
                    <p style="font-size: 14px;">Our support team is obsessed with making sure your business runs smoothly. We are always willing and ready to provide solutions to any issue you may have.</p>
                </div>
                <div class="col-md-4">
                    <a :href="helpCenterURL" class="ond-btn-p">Contact Support</a>
                </div>
            </div>
        </section>

        <!---------- Queue Management ---------->
        <section style="background-color: #f4f4f4">
            <div class="container section_height">
                <div class="row align-items-center">
                    <!-- <div class="col-12 pb-4 text-center">
                        <h3>Queue Managment System</h3>
                    </div> -->
                    <div class="col-md-6 col-lg-5 order-md-2 ps-lg-5 text-center text-md-start">
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/qms.png" class="img-fluid" />
                    </div>

                    <div class="col-md-6 col-lg-5 order-md-1 offset-lg-1 ps-lg-5 text-center text-md-start pt-4 pt-md-1">
                        <h2 class="mb-4">Increase sales with our last-minute appointments tool.</h2>
                        <p>Manage your traffic and get more visibility! A dedicated professional can render services to your client in the queue. This helps your walk-in clients manage their time.</p>
                        <!-- <a href="#" class="pe-2"> Learn more</a> -->
                        <!-- <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" /> -->
                    </div>

                </div>
            </div>
        </section>

        <!---------- Queue Management Features ---------->
        <section>
            <div class="container">
                <div class="row align-items-center section_height">
                    <div class="col-md-6 col-lg-6 text-center text-md-start">
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/queue.jpg" class="img-fluid" />
                    </div>

                    <div class="col-md-6 ps-lg-5 pt-4 pt-md-1">
                        <h2 class="mb-3">Queue Management System features allow you to</h2>
                        <ul class="queue_ul">
                            <li>
                                <p>Accept Clients via Virtual Queue From Within a 15-mile Radius Of Your Business Location</p>
                            </li>
                            <li>
                                <p>Accept Walk-in Clients</p>
                            </li>
                            <li>
                                <p>Manage Queue in Real-time By Utilizing The Queue Screen</p>
                            </li>
                            <li>
                                <p>View Business Trends and Analytics</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 mt-4 mt-md-5 text-center" v-if="$storage.getStorageSync('token') && $storage.getStorageSync('user').user_role != 'business'">
                        <router-link :to="{name:'AddBusiness'}" class="btn btn-ondaq-dark py-2 px-5 fs-6">Get Started</router-link>
                    </div>
                    <div class="col-12 mt-4 mt-md-5 text-center" v-if="!$storage.getStorageSync('token')" >
                        <button data-bs-toggle="modal" data-bs-target="#signupModal" class="btn btn-ondaq-dark py-2 px-5 fs-6">Start For Free</button>
                    </div>
                </div>
            </div>
        </section>

        <!---------- Frequently asked questions ---------->
        <section class="container section_height">
            <div class="row">
                <div class="col-12 pb-4 text-center">
                    <h3>Frequently Asked Questions</h3>
                </div>
                <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                    <div class="accordion" id="myAccordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button border-bottom" data-bs-toggle="collapse" data-bs-target="#q1">How many products does Ondaq offer?</button>
                            </h2>
                            <div id="q1" class="accordion-collapse collapse show">
                                <div class="card-body">
                                    <p class="p-3 m-0">As a business, you have access to use our virtual queuing system or scheduling systems. You may turn off one and use the other. And this solely depends on the nature of your business. For example, if you do not have a salon or a physical location for your business, you can not use the virtual queuing system as a business.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed border-bottom" data-bs-toggle="collapse" data-bs-target="#q2">What is a virtual queuing management system?</button>
                            </h2>
                            <div id="q2" class="accordion-collapse collapse">
                                <div class="card-body">
                                    <p class="p-3 m-0">Ondaq virtual queuing system is a time management tool that allows your clients to join the real-time queue for service within an estimated time. Clients can view other customers from their computers or mobile phone. Periodically they get alerted via email and text messages to know when their turn is on the waitlist.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed border-bottom" data-bs-toggle="collapse" data-bs-target="#q3">I can not find my business type when creating an account.</button>
                            </h2>
                            <div id="q3" class="accordion-collapse collapse">
                                <div class="card-body">
                                    <p class="p-3 m-0">We are here to serve you better. Kindly send an email to support@ondaq.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                howWorkData:[],
                bannerImage: '',
                helpCenterURL : process.env.VUE_APP_ZENDESK_SUB_DOMAIN
            }
        },
        methods:{
            bannerBgImage(img){
                this.bannerImage = 'background-image: url("'+img+'")'
            },
            bannerData(){
                let thiss = this
                axios.get('bannerData/How it Works').then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.howWorkData = response.data.data[0]
                        console.log(thiss.howWorkData)
                        thiss.bannerBgImage(thiss.howWorkData.banner_image)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            thiss.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.toast.error(error.response.data.message);
                        }
                    }
                });
            }
        },
        mounted() {
            this.bannerData()
        }
    }
</script>
<style scoped>
    .section_height{
        padding: 60px 10px;
    }
    .banner-text{
        font-size: 18px;
    }
    .svg-inline--fa.fa-w-16{
        color: #6e317a;
    }
    .accordion-button{
        background-color: #ffffff;
        font-size: 1.2rem;
        color: #640e99
    }
    .accordion-button::after{
        background-image: url('../../assets/images/pricing/down.svg')
    }

</style>
